<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-add-program"
      v-model="showModal"
      title="Duplicate"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="pristine || invalid"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-row class="mb-2">
        <client-program :program="program" />
      </b-row>

      <b-form @submit.prevent>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="Organisation"
              rules="required"
              name="Organisation"
            >
              <b-form-group 
                label="Organisation"
                label-for="Organisation"
              >
                <v-select
                  v-model="duplicateProgram.client_id"
                  :dir="dir"
                  placeholder="Please select a Organisation"
                  :options="clientsList"
                  :reduce="(option) => option.value"
                  label="text"
                >
                  <template v-slot:option="option">
                    {{ option.text }} - {{ option.domain }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="name"
              :rules="{ required: true }"
              vid="name"
              name="Program Name"
            >
              <b-form-group
                label="Program Name"
                label-for="program-name"
              >
                <b-form-input
                  id="program-name-input"
                  v-model="duplicateProgram.name"
                  placeholder="Program Name"
                  maxlength="255"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                  @change="onNameChange"
                />
                <b-form-invalid-feedback>
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <validation-provider
              v-slot="validationContext"
              ref="subdomain"
              :rules="{ required: true }"
              vid="path"
              name="Program Path"
            >
              <b-form-group
                label="Program Path"
                label-for="program-path-input"
              >
                <b-input-group
                  prepend="organisation-url/"
                  append="/"
                >
                  <b-form-input
                    id="program-path-input"
                    v-model="duplicateProgram.path"
                    placeholder="Path"
                    maxlength="63"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                </b-input-group>
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Index URL"
              label-for="indexUrl"
            >
              <b-form-checkbox
                id="indexedSwitch"
                v-model="duplicateProgram.indexed"
                checked="false"
                name="index-url"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-alert
              show
              variant="info"
              class="p-1"
            >
              <span class="text-muted">Mentees and Mentors may only be transferred within the same Organisation</span>
            </b-alert>
          </b-col>
          <b-col sm="6">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Content to Duplicate"
            >
              <b-form-checkbox-group
                v-model="selected"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="content-to-duplicate"
                stacked
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="2"
            class="d-flex justify-content-end"
          >
            <feather-icon
              v-b-tooltip.hover.right="'When duplicating with Mentors/Mentees they will be carried across to the new program and will receive an invitation to participate again in the new program. Their status will be updated to Inactive - Pending. The email will include the new program URL.'"
              icon="InfoIcon"
              size="20"
            />
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          @click="ok()"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>Submit</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>
  
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BModal,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInvalidFeedback,
  VBModal,
  BButton,
  BSpinner,
  VBTooltip, 
  BAlert
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required, max } from "@validations";
import {
  toFriendlyUrlPart,
  getValidationState,
  makeErrorToast,
  makeSuccessToast
} from "@/libs/utils";
import clientsService from "@/services/clientsService";
import { ProgramClass } from '@models/classes/programClass';
import { AOM_MAIN_DOMAIN } from '@/constants/app';
import ClientProgram from '@/views/apps/admin/programs/general/ClientProgram.vue';

export default {
  name: 'DuplicateProgram',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BModal,
    vSelect,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
    ClientProgram,
    BFormCheckboxGroup,
    BAlert
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
    "b-tooltip": VBTooltip
  },
  props: {
    show: {
      type: Boolean
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  data(vm) {
    return {
      name: null,
      dir: "ltr",
      clientsList: [],
      showModal: false,
      duplicateProgram: new ProgramClass(vm.program),
      isUpdating: false,
      options: [
        { text: 'With Mentors', value: 'with_mentors'},
        { text: 'With Mentees', value: 'with_mentees' },
        { text: 'With Mentor Answers', value: 'with_mentor_answers' },
        { text: 'With Mentee Answers', value: 'with_mentee_answers' },
        { text: 'With Applications', value: 'with_applications' },
        { text: 'With Surveys', value: 'with_surveys' },
        { text: 'With Resources', value: 'with_resources' },
        { text: 'With Communcations', value: 'with_communications' },
        { text: 'With Trainings', value: 'with_trainings' }
      ],
      selected: []
    };
  },
  computed: {
    duplicateProgramModel() {
      return {
        from_program_id:  this.program.id,
        name: this.duplicateProgram.name,
        path: this.duplicateProgram.path,
        indexed: this.duplicateProgram.indexed,
        licence_start_date: this.duplicateProgram.licence_start_date,
        licence_end_date: this.duplicateProgram.licence_end_date,
        with_mentors: this.selected.includes('with_mentors'),
        with_mentees: this.selected.includes('with_mentees'),
        with_mentor_answers: this.selected.includes('with_mentor_answers'),
        with_mentee_answers: this.selected.includes('with_mentee_answers'),
        with_applications: this.selected.includes('with_applications'),
        with_surveys: this.selected.includes('with_surveys'),
        with_resources: this.selected.includes('with_resources'),
        with_communications: this.selected.includes('with_communications'),
        with_trainings: this.selected.includes('with_trainings')
      };
    }

  },  
  watch: {
    show(n) {
      this.showModal = n;
    },
    program: {
      handler() {
        new ProgramClass(this.program);
      }, 
      deep: true,
      immediate: true
    },
    'duplicateProgram.client_id':{
      handler() {
        this.disableContentOptions();
      },
      immediate: true
    }
  },
  created() {
    this.loadClients();
  },
  methods: {
    onNameChange() {
      if (this.duplicateProgram?.name) {
        this.duplicateProgram.path = toFriendlyUrlPart(this.duplicateProgram.name);
      }
    },
    async loadClients() {
      try {
        const response = await clientsService.getList();
        this.clientsList = response.data.items.map(client => {
          // TODO make domain dynamic
          return {value: client.id, text: client.name, domain: `${client.path}.${AOM_MAIN_DOMAIN}`};
        });
      } catch (e) {
        this.$toast(makeErrorToast("Organisations List not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.duplicateProgram = new ProgramClass();
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$emit('close');
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        const clientId = this.duplicateProgram.client_id;
        await clientsService.duplicateClientProgram(clientId, this.duplicateProgramModel);
        this.showModal = false;
        this.$emit("duplicated");
        this.$toast(makeSuccessToast("Program duplicated sucessfully."));
        await this.$store.dispatch('programs/GET_PROGRAMS');  
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$toast(makeErrorToast("Something went wrong. Program not duplicated."));
        }
      } finally {
        this.isUpdating = false;
      }
    },
    disableContentOptions() {
      if(Number(this.program.client_id) !== Number(this.duplicateProgram.client_id)) {
        this.options = this.options.map(o => {
          if(
            o.value === 'with_mentors' || 
            o.value === 'with_mentees' || 
            o.value === 'with_mentor_answers' || 
            o.value === 'with_mentee_answers'
          ) {
            return {
              ...o,
              disabled: true
            };
          } 
          return o;
        });
      } else {
        this.options = this.options.map(o => ({...o, disabled: false}));
      }
    }
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>